import React, { useState, useEffect } from 'react'
import { FaFilter } from "react-icons/fa"
import { AiFillPrinter } from "react-icons/ai"
import { BiSearch } from "react-icons/bi"
import './ledger.scss'
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { useFetchCustomerContext } from '../../../contexts/FetchCustomersContext';
import { useFetchCashBook } from '../../../contexts/FetchCashBook';
import { message } from 'antd';
import { useAuthcontexts } from '../../../contexts/Authcontexts';

const initialState = { show: 30, accountName: "" }


export default function Ledger() {
    const [state, setState] = useState(initialState);
    const [currentPage, setCurrentPage] = useState(1)
    const [selectFromDate, setSelectFromDate] = useState("");
    const [selectToDate, setSelectToDate] = useState("");
    const [account, setAccount] = useState([]);
    const [filterAccount, setFilterAccount] = useState([]);
    const [openingBalance, setOpeningBalance] = useState(0);
    const [totalBalance, setTotalBalance] = useState([]);
    const [isloading, setIsLoding] = useState(false)


    // fetch Data of Cutomers

    const { user } = useAuthcontexts();
    const { customers } = useFetchCustomerContext();
    const { getCashBook } = useFetchCashBook();


    let transections = [...getCashBook]
    const handleChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const handleFromDate = e => {
        setSelectFromDate(e.target.value)
    }
    const handleToDate = e => {
        setSelectToDate(e.target.value)
    }


    // Sum Function

    // const sumFunction = (abc) => {
    //     let sumCredit = 0;
    //     for (const key in abc) {
    //         if (abc.hasOwnProperty(key)) {
    //             const mark = abc[key].credit;
    //             sumCredit += mark;
    //         }
    //     }
    //     return sumCredit
    // }



    useEffect(() => {
        const currentDate = new Date();
        const toDate = currentDate.toISOString().slice(0, 10);

        currentDate.setMonth(currentDate.getMonth() - 6);
        const fromDate = currentDate.toISOString().slice(0, 10)

        setSelectToDate(toDate);
        setSelectFromDate(fromDate);

    }, []);

    // sorting Customers list 

    const sortedCustomers = [...customers].sort((a, b) => a.fullName.localeCompare(b.fullName));

    //  Displaying Customer ledger Function

    const handleFind = () => {

        let { accountName } = state

        if (!accountName || accountName === "") {
            return message.error("please Select Your Account Name")
        }
        setCurrentPage(1)
        setIsLoding(true)
        let selectedCustomer = customers.find((entity) => entity.fullName === accountName)

        let selectedAc = transections.filter((entity) => entity.customerID === selectedCustomer.id)

        let sortedSelectedAc = selectedAc.sort((a, b) => new Date(a.date) - new Date(b.date));
        setAccount(sortedSelectedAc)
        setFilterAccount(sortedSelectedAc)
        const abc = []

        if (selectedCustomer) {
            setOpeningBalance(selectedCustomer.openingBalance)
        }
        else {
            setOpeningBalance(0)
        }
        let sumDebit = 0;
        let sumCredit = 0;
        let sum = 0;
        for (const key in sortedSelectedAc) {
            if (sortedSelectedAc.hasOwnProperty(key)) {
                let mark1 = sortedSelectedAc[key].credit;
                let mark2 = sortedSelectedAc[key].debit;
                sumCredit += mark1
                sumDebit += mark2
                sum += mark1 - mark2
            }
        }

        abc.push({ sumDebit, sumCredit, sum })
        setTotalBalance(abc)
        setIsLoding(false)
    }

    const handleDateFilter = () => {
        let filterTransections = filterAccount.filter((entity) => {
            return entity.date >= selectFromDate && entity.date <= selectToDate
        })
        if (filterTransections) {
            setAccount(filterTransections)
        }
    }

    const handlePrintPDF = () => {
        const doc = new jsPDF();
        const title = `${(user?.companyName).toUpperCase() || 'Your Company Name'} - Account Ledger`;
        const subTitle = `Account Name: ${state.accountName.toUpperCase()} | Opening Balance: ${openingBalance.toLocaleString()}`;

        const tableColumn = ["Date", "No#", "Type", "Description", "Debit", "Credit"];
        const tableRows = account.map((item) => [
            item.date,
            item.vocherNo || item.invoiceNo,
            item.vocherType,
            item.description || item.details || item.customerName,
            item.debit.toLocaleString(),
            item.credit.toLocaleString()
        ]);

        const remainingBalance = (totalBalance[0]?.sum || 0) + (openingBalance || 0);
        const finalText = remainingBalance > 0
            ? `${remainingBalance.toLocaleString()} Cr`
            : `${Math.abs(remainingBalance).toLocaleString()} Dr`;

        tableRows.push([
            '', '', '', 'Remaining Balance',
            '', finalText
        ]);

        const headerX = 14;
        const headerY = 10;
        const headerWidth = 185;
        const headerHeight = 25;
        const radius = 2;

        // Set header background and text color
        doc.setFillColor(22, 160, 133);
        doc.roundedRect(headerX, headerY, headerWidth, headerHeight, radius, radius, 'F');

        doc.setTextColor(255, 255, 255);
        doc.setFont("helvetica", "bold");
        doc.setFontSize(16);
        doc.text(title, headerX + 10, headerY + 12);

        doc.setFontSize(12);
        doc.text(subTitle, headerX + 10, headerY + 20);


        // Add the table with black text in table content
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: headerY + headerHeight + 5,
            theme: 'grid',
            styles: {
                font: 'helvetica',
                fontSize: 9,
                textColor: [0, 0, 0], // Set all table text to black
                cellPadding: 2,
                overflow: 'linebreak',
                lineColor: [44, 62, 80],
            },
            columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 15 },
                2: { cellWidth: 15 },
                3: { cellWidth: 65 },
                4: { cellWidth: 30, halign: 'right', fillColor: [249, 249, 249] },
                5: { cellWidth: 30, halign: 'right', fillColor: [232, 232, 232] },
            },
            headStyles: {
                fillColor: [22, 160, 133],
                textColor: [255, 255, 255],
                fontStyle: 'bold',
            },
            footStyles: {
                fillColor: [22, 160, 133],
                textColor: [255, 255, 255],
                fontStyle: 'bold',
                halign: 'center',
            },
            didDrawPage: (data) => {
                let str = "Page " + doc.internal.getNumberOfPages();
                doc.setFontSize(10);
                doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
            },
        });

        // Save the PDF
        doc.save(`${state.accountName}_ledger.pdf`);
    };


    const date = new Date();
    const currentTime = date.toLocaleTimeString();
    const currentDay = date.toDateString();


    const rowsPerPage = 30;
    const lastIndex = currentPage * rowsPerPage;
    const firstIndex = lastIndex - rowsPerPage;
    const data = account.slice(firstIndex, lastIndex);
    const npages = Math.ceil(account.length / rowsPerPage);
    const number = [...Array(npages + 1).keys()].slice(1)

    const handleChangePage = (id) => {
        setCurrentPage(id)
    }
    const handlePre = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    const handleNext = () => {
        if (currentPage !== npages) {
            setCurrentPage(currentPage + 1)
        }
    }

    return (
        <>
            <div className="container bg-light">
                <div className="row">
                    <div className="col m-2 bg-white vh-100 overflow-scroll abc rounded-3">
                        <div className="row">
                            <div className='mx-0 mt-3 d-flex flex-row justify-content-between' id='cashVocher'>
                                <div><h4 className='mt-2'>Ledger</h4></div>
                                <div id='btnStyle'>
                                    <button className='btn btn-outline-secondary px-3 me-2' style={{ color: "#a6a6a6" }} data-bs-toggle="modal" data-bs-target="#exampleModal" > <FaFilter /> <span className='d-none d-sm-inline'>Filter</span></button>
                                    <button className='btn btn-light px-3 text-white' style={{ background: "#005a16" }} onClick={handlePrintPDF}> <AiFillPrinter /> <span className='d-none d-sm-inline'>Print</span></button>
                                </div>
                            </div>
                        </div>
                        <hr className='bg-secondary' />


                        <div className='row'>
                            <div className="container-fluid">
                                <div className="row mt-1">
                                    <div className="col">
                                        <div className='mb-3 d-flex flex-row justify-content-between'>
                                            <div className='d-flex flex-row me-1'>
                                                <span className=' d-none d-sm-inline fw-bold me-2 mt-1'>Show </span>
                                                <select className="form-control-sm ms-lg-0" name='show' onChange={handleChange}>
                                                    <option>01</option>
                                                    <option>05</option>
                                                    <option>10</option>
                                                    <option>15</option>
                                                    <option>20</option>
                                                </select>
                                                <span className='ms-2 mt-1 d-none d-sm-inline  fw-bold'>Rows</span>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="input-group">
                                            <select className="form-control rounded-start" name='accountName' value={state.accountName} onChange={handleChange}>
                                                <option value="">Select</option>
                                                {
                                                    sortedCustomers.map((acName, index) => {
                                                        return (
                                                            <option key={index} value={acName.fullName}>{acName.fullName + " " + acName.city}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="input-group-text bg-white" id="basic-addon1" onClick={handleFind}><BiSearch /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className='bg-secondary' />
                        <div className="row">
                            <div>
                                <div className="row" >
                                    <div className="col">
                                        <div className='printContent d-none mb-5 mt-2'>
                                            <div className='row d-flex justify-content-between'>
                                                <div className='col' style={{ color: "#0c6321" }}>
                                                    <span className='fw-bold fs-4 py-4'> {user.companyName ? user.companyName : 'Zaman Ali'}</span>
                                                </div>

                                                <div className='col text-center' style={{ color: "#0c6321" }}><h4>Account Ledger</h4></div>
                                                <div className='col fw-bold text-end' style={{ color: "#0c6321" }}>{currentDay}  {currentTime} </div>

                                            </div>
                                        </div>
                                        <p className='px-3 fs-5' style={{ color: "#005a16", fontWeight: "500", textTransform: 'capitalize' }}>Account name: {state.accountName}</p>
                                    </div>
                                </div>
                                <p className='px-3 text-end fw-bold' style={{ color: "#005a16" }}>OpeningBalance: {openingBalance}</p>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            {
                                                isloading ?
                                                    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "400px" }}>
                                                        <div className="spinner-border text-success" role="status" style={{ height: '3rem', width: "3rem" }}>

                                                        </div>
                                                    </div>
                                                    :

                                                    <div className="table-responsive rounded">
                                                        <table className="table table-sm">
                                                            <thead>
                                                                <tr className='text-center' style={{ background: "#c1e6c3", color: "#005a16" }}>

                                                                    <th>Date</th>
                                                                    <th>No#</th>
                                                                    <th>Type</th>
                                                                    <th>Description</th>
                                                                    <th>Debit</th>
                                                                    <th>Credit</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {

                                                                    data.slice(0, state.show).map((entity, i) => {
                                                                        return (
                                                                            <tr key={i} className='text-center'>

                                                                                <td>{entity.date}</td>
                                                                                {
                                                                                    entity.vocherNo ?
                                                                                        <td>{entity.vocherNo}</td>
                                                                                        : <td>{entity.invoiceNo}</td>
                                                                                }
                                                                                <td>{entity.vocherType}</td>
                                                                                {
                                                                                    entity.description ?
                                                                                        <td>{entity.description}</td>
                                                                                        : entity.details ?
                                                                                            <td>{entity.details}</td>
                                                                                            : <td>{entity.customerName}</td>
                                                                                }
                                                                                <td>{entity.debit?.toLocaleString()}</td>
                                                                                <td>{entity.credit?.toLocaleString()}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }


                                                            </tbody>

                                                            {
                                                                totalBalance.map((total, index) => {
                                                                    return (

                                                                        <tfoot key={index}>
                                                                            <tr>
                                                                                <td colSpan={4} className='text-center fw-bold'>Total Balance</td>
                                                                                <td className='text-center fw-bold'>{(total.sumDebit?.toLocaleString())} </td>
                                                                                <td className='text-center fw-bold'>{total.sumCredit?.toLocaleString()} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={4} className='text-center fw-bold'>Remaining Balance</td>
                                                                                {(total.sum + openingBalance) > 0 ?
                                                                                    <td colSpan={2} className='text-center fw-bold' style={{ color: '#198754' }}>{(total.sum + openingBalance) > 0 ? (total.sum + openingBalance)?.toLocaleString() : (-(total.sum + openingBalance))?.toLocaleString()}<span className='text-black ps-2'>Cr</span></td>

                                                                                    : <td colSpan={2} className='text-center text-danger fw-bold'>{(total.sum + openingBalance) > 0 ? (total.sum + openingBalance)?.toLocaleString() : (-(total.sum + openingBalance))?.toLocaleString()}<span className='text-black ps-2'>Dr</span></td>
                                                                                }
                                                                            </tr>
                                                                        </tfoot>


                                                                    )
                                                                })
                                                            }
                                                        </table>
                                                    </div>
                                            }
                                            {
                                                data.length ?
                                                    <nav>
                                                        <ul className="pagination pagination-sm justify-content-end">
                                                            <li className="page-item">
                                                                <button className="page-link" aria-label="Previous" onClick={handlePre}>
                                                                    Prev
                                                                </button>
                                                            </li>
                                                            {
                                                                number.map((n, i) => (
                                                                    <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                                        <button className="page-link" onClick={() => handleChangePage(n)}>{n}</button>
                                                                    </li>
                                                                ))
                                                            }
                                                            <li className="page-item">
                                                                <button className="page-link" aria-label="Next" onClick={handleNext}>
                                                                    Next
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal  */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Filter</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row px-3">
                                <div className="col-12">
                                    <label style={{ color: "#6fa17b" }}>From Date</label>
                                </div>
                                <div className="col-6 py-2">
                                    <input type="date" className='form-control form-control-sm rounded' name="fromDate" value={selectFromDate} onChange={handleFromDate} />
                                </div>
                            </div>
                            <div className="row px-3">
                                <div className="col-12">
                                    <label style={{ color: "#6fa17b" }}>To Date</label>
                                </div>
                                <div className="col-6 py-2">
                                    <input type="date" className='form-control form-control-sm rounded' name="toDate" value={selectToDate} onChange={handleToDate} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn rounded" data-bs-dismiss="modal" style={{ background: "#c1e6c3", color: "#005a16" }}>Close</button>
                            <button type="button" className="btn rounded" style={{ background: "#005a16", color: "white" }} onClick={handleDateFilter} data-bs-dismiss="modal">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
